import React from "react";

const Contacts = () => {
  return (
    <section className=" section-spacing-80 dark-bg" id="contacts">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-head center width-300 margin-auto">
              <h3 className="section-title title light-yellow">
                Get in Touch Direct
              </h3>
            </div>
            <div className="section-body section-spacing-40 no-padding-bottom ">
              <div className="social-container contact-container flex">
                <div className="contact-card">
                  <h3>Call Us</h3>
                  <div className="contact-card-body">
                    <p>
                      <a href="tel:+13152742122">+1 (315) 274-2122</a>{" "}
                    </p>
                    <p>
                      <a href="tel:+256700741111">+256 (700) 741-111</a>{" "}
                    </p>
                    <p>
                      <a href="tel:+256782167879">+256 (782) 167-879</a>{" "}
                    </p>
                  </div>
                </div>
                <div className="contact-card">
                  <h3>WhatsApp Direct</h3>
                  <div className="contact-card-body">
                    <p>
                      <a
                        href="https://wa.me/13152742122"
                        rel="noreferrer"
                        target="_blank"
                      >
                        +1 (315) 274-2122
                      </a>{" "}
                    </p>
                    <p>
                      <a
                        href="https://wa.me/256700741111"
                        rel="noreferrer"
                        target="_blank"
                      >
                        +256 (700) 741-111
                      </a>{" "}
                    </p>
                    <p>
                      <a
                        href="https://wa.me/256782167879"
                        rel="noreferrer"
                        target="_blank"
                      >
                        +256 (782) 167-879
                      </a>{" "}
                    </p>
                  </div>
                </div>
                <div className="contact-card">
                  <h3>Social Media</h3>
                  <div className="contact-card-body">
                    <p>
                      <span className=" right-push-8">Twitter:</span>{" "}
                      <a
                        href="https://twitter.com/MalidadBooks"
                        rel="noreferrer"
                        target="_blank"
                      >
                        @MalidadBooks
                      </a>{" "}
                    </p>
                    <p>
                      <span className=" right-push-8">Twitter:</span>{" "}
                      <a
                        href="https://twitter.com/MALIDADcom"
                        rel="noreferrer"
                        target="_blank"
                      >
                        @MALIDADcom
                      </a>{" "}
                    </p>
                    <p>
                      <span className=" right-push-8">Facebook:</span>{" "}
                      <a
                        href="https://facebook.com/MALIDADcom"
                        rel="noreferrer"
                        target="_blank"
                      >
                        MALIDAD.COM
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-footer center section-spacing-40 no-padding-bottom ">
              <a
                href="#"
                className="button section-cta white-bg dark uppercase bold"
              >
                Send Us Email
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
