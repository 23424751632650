import React from "react";

const ReviewerCard = (props) => {
  return (
    <div className="review-card">
      <h3 className="review-brief">{props.brief}</h3>
      <div className="review section-spacing-20">{props.children}</div>
      <div className="reviewer flex">
        <img src={props.revImg} className="reviewer-img" />
        <div className="reviewer-details">
          <h3 className="reviewer-name">{props.revName}</h3>
          <p className="reviewer-bio">{props.revBio}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewerCard;
