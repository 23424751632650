import React from "react";
import { Slide } from "react-reveal";
import fahad from "../../assets/images/reviewers/fahad.jpg";
import ali from "../../assets/images/reviewers/ali.jpg";
import hanta from "../../assets/images/reviewers/hanta.jpg";
import km from "../../assets/images/reviewers/km.jpg";
import chris from "../../assets/images/reviewers/chris.jpg";
import asha from "../../assets/images/reviewers/asha.jpg";
import magezi from "../../assets/images/reviewers/magezi.jpg";
import shaf from "../../assets/images/reviewers/shaf.jpg";
import aggrey from "../../assets/images/reviewers/aggrey.jpg";
import malbie from "../../assets/images/reviewers/malbie.jpg";
import ibrah from "../../assets/images/reviewers/ibra.jpg";
import ReviewerCard from "../reusables/ReviewerCard";

const Reviews = () => {
  return (
    <section className=" threads-bg section-spacing-100" id="reviews">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center width-300 margin-auto">
                <h3 className="section-title title">
                  <span className=" light-yellow">
                    The Lifetime Treasure Book
                  </span>{" "}
                  Reviews
                </h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-50">
              <div className="reviews-cards-container relative carousel">
                <div className="background-bar light-blue-bg"></div>
                <div className="margin-auto">
                  <div className="reviews-cards-inner slider-items-container center-slide-arrows">
                    <ReviewerCard
                      revImg={aggrey}
                      revName="Aggrey Osuta Snr"
                      revBio="Entreprenuer and Medic"
                      brief="The Lifetime Treasure book is a generous companion that arms you with skills that'll give you unlimited leverage in your quest for better living"
                    >
                      <p>
                        The Lifetime Treasure book is a book of forever profits.
                        A generous companion that arms you with skills that'll
                        give you unlimited leverage in your quest for better
                        living. Make it a point to read it and learn. It was
                        worth every minute of my time and I think it will be the
                        same to you.
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={malbie}
                      revName="Bruno Mukisa"
                      revBio="Writer, Architect and Poet"
                      brief="The Lifetime Treasure book teaches you how to have an edge over everyone else in coping with life"
                    >
                      <p>
                        Learning, in life, comes to us in varied ways. For some,
                        they wait for circumstances to occur such that they can
                        learn from the experience gained. Others have, however,
                        come to realize that it's more rewarding to learn first
                        then experience.
                      </p>
                      <p className=" margin-top-10">
                        Offering a countless list of life-transforming skills,
                        The Lifetime Treasure book teaches you to prepare better
                        for life's experiences and how to have an edge over
                        everyone else in coping with life.
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={ibrah}
                      revName="Sheikh Kasumba Amiiru Ibrahim"
                      revBio="Teacher and Muslim Clergy"
                      brief="The Lifetime Treasure book is really the kind of environment every individual; juvenile and senile have to dwell in for the best ahead"
                    >
                      <p>
                        It's said: "life is unfair", but for one who's living on
                        our environment can find it fair. It requires one a
                        basket of skills to adapt accordingly in line with our
                        environment.
                      </p>
                      <p className=" margin-top-10">
                        The Lifetime Treasure book guides to a range of generic
                        skills that have to be learned and mastered for a
                        successful life. The Lifetime Treasure is really the
                        kind of environment every individual; juvenile and
                        senile have to dwell in for the best ahead.
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={shaf}
                      revName="Businge Shafique"
                      revBio="Lawyer"
                      brief="Indeed The Lifetime Treasure book acquaints its reader practical magnanimous skills that are a requisite to a remarkable life"
                    >
                      <p>
                        Just because someone is old does not make them wise.
                        Wisdom comes from experience, reflection and knowledge
                        which only a few people acquire in a lifetime.
                      </p>
                      <p className=" margin-top-10">
                        The Lifetime Treasure book offers a non-tangible
                        treasure to the old and young. Lawyers say; Res ipsa
                        loquitor, indeed the book acquaints its reader practical
                        magnanimous skills that are a requisite to a remarkable
                        life.
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={magezi}
                      revName="Magezi Bashir"
                      revBio="Teacher &amp; Founder EducateHer.org"
                      brief="The Lifetime Treasure book should be incoporated in the syllabus of the education system at all levels"
                    >
                      <p>
                        Being a Teacher, I surely believe The lifetime Treasure
                        should be somehow incoporated in the syllabus of the
                        education system at all levels. So that students can
                        learn and grow practicing these skills.
                      </p>
                      <p className=" margin-top-10">
                        The Lifetime Treasure book is a real Treasure only that
                        it is readily available for everyone to access and read.
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={fahad}
                      revName="Fahad Bruhan Jr."
                      revBio="Entrepreneur, Thought Leader in Business, Investment and Personal finance"
                      brief="I wish I read The Lifetime Treasure book many years ago!"
                    >
                      <p>
                        We live in a time where we need to pursue education
                        beyond the formal classes to position ourselves
                        favourably in the market place.
                      </p>
                      <p className=" margin-top-10">
                        We need to widely self-educate, acquire the right
                        skills, search for relevant information, and mentorship.
                        If this is your journey, this book is the perfect guide
                        you need. I wish I read it many years ago!
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={chris}
                      revName="Aryatunga Criscent"
                      revBio="Business Executive"
                      brief="If you want exceptional wisdom on how to perfectly live the best of your life and never regret, read The Lifetime Treasure book"
                    >
                      <p>
                        The simplicity of this book will help everyone read it,
                        enjoy and grasp every key message to their nerve. If you
                        want exceptional wisdom on how to perfectly live the
                        best of your life and never regret, read the lifetime
                        treasure book.
                      </p>
                      <p className=" margin-top-10">
                        Irrespective of your age, as long as you can get every
                        key message in this book then you will be unstoppable. I
                        would advise every parent to get a copy for their
                        children, they will learn what even parents wouldn't be
                        in position to teach them.
                      </p>
                    </ReviewerCard>

                    <ReviewerCard
                      revImg={km}
                      revName="Kamukama Priscillah"
                      revBio="Nurse, Kabarole Hospital"
                      brief="I learned to accept and deal with myself and others"
                    >
                      <p>
                        Dealing with certain issues or oneself may not be easy
                        but with The Lifetime Treasure, I learned to accept and
                        deal with myself and others. It is really a treasure.
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={ali}
                      revName="Ataho Ndori Ali"
                      revBio="Business Owner and Activist"
                      brief="The Lifetime Treasure book helped me learn to put my intellect before my emotions"
                    >
                      <p>
                        If you really want to realize mistakes we figure out in
                        50s that we did in 20s look no further. If I must say
                        one thing I've learnt in this book is that it has helped
                        me learn to put my intellect before my emotions and that
                        if you lay a good plan to succeed lay the best plan for
                        failure too.
                      </p>
                    </ReviewerCard>

                    <ReviewerCard
                      revImg={hanta}
                      revName="Josh Kaweesa aka “Hanta UG”"
                      revBio="Musician"
                      brief="All the chapters in The Lifetime Treasure book have a solution to every situation that one might encounter in their day-to-day life"
                    >
                      <p>
                        Being part of this great masterpiece is one of my best
                        achievements in life. I got a chance to look outside the
                        box and standout extraordinarily since I started working
                        hand in hand with great minds and met new people in
                        different personalities.
                      </p>
                      <p className=" margin-top-10">
                        This gave me a tremendous chance to understand the
                        beauty of a lifetime. All the chapters in this book have
                        a solution to every situation that one might encounter
                        in their day-to-day life and for that case, The Lifetime
                        Treasure is surely a blessing.
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={asha}
                      revName="Aisha Namugenyi"
                      revBio="Sheikhat, House Wife"
                      brief="The Lifetime Treasure book has helped me give my kids the best mother-child education"
                    >
                      <p>
                        As a house wife, few things have bettered my marriage
                        life than The Lifetime Treasure book.
                      </p>
                      <p className=" margin-top-10">
                        This book is really a treasure. I read it to my kids in
                        our free time, it has helped me bond more and better
                        with my kids. At the same time helping me give my kids
                        the best mother-child education.
                      </p>
                    </ReviewerCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
