import React from "react";
const ReviewSlideCard = (props) => {
  return (
    <div className="text-side-review">
      <p className="bold hero-reviewer dark">— {props.revName}</p>
      <p className="hero-review dark">&ldquo;{props.revText} &rdquo;</p>
    </div>
  );
};

export default ReviewSlideCard;
