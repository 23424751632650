import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { heroReviewsCarousel } from "../reusables/heroReviewsCarousel";
import star from "../../assets/images/icons/star.svg";
import right from "../../assets/images/icons/right.svg";
import cover from "../../assets/images/content/cover.png";
import { Slide } from "react-reveal";
import ReviewSlideCard from "../reusables/ReviewSlideCard";
import wft from "../../assets/images/content/wft.png";
import visa from "../../assets/images/pay/visa.svg";
import airtel from "../../assets/images/pay/airtel.svg";
import mtn from "../../assets/images/pay/mtn.svg";
import mastercard from "../../assets/images/pay/mastercard.svg";

const HomeHero = () => {
  return (
    <section className="hero">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="hero-contents-container flex">
              <div className="text-side">
                <div className="hero-rating flex">
                  <img src={star} alt="Star" className="icon" />
                  <p className="bold">4.8</p>
                  <p className="bold dark">Reader Reviews</p>
                </div>
                <div className="text-side-body">
                  <h2 className="hero-title">
                    The Lifetime <span className="block"></span>
                    Treasure Book
                  </h2>
                  <p className="hero-subtitle capitalize">
                    <span className="bold">Learn</span> and{" "}
                    <span className="bold">master</span> the{" "}
                    <span className="bold">18 skills</span> that{" "}
                    <span className="bold">pay off forever</span> but{" "}
                    <span className="bold">school</span> will{" "}
                    <span className="bold">not teach</span> you
                  </p>
                </div>
                <div className="text-side-cta flex">
                  <div className="main-cta modal-container">
                    <p className="left rich-small margin-bottom-10 hero-offer">
                      <span className="block bold uppercase red">
                        RETAIL PRICE:
                      </span>

                      <span className=" block margin-top-10">
                        UGX: <span className=" bold">94,999</span> | USD{" "}
                        <span className=" bold">$27.99</span>
                      </span>
                    </p>
                    <div>
                      <a
                        href="/buy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button flex light-yellow-button bold uppercase modal-trigger"
                      >
                        <span className="right-push-4">Order Your Copy</span>
                        <img src={right} alt="Right Arrow" className="icon" />
                      </a>
                      <div className="accepted-payments">
                        <p className="rich-small uppercase bold">PAY WITH</p>
                        <div className="flex">
                          <img src={visa} alt="VISA" />
                          <img src={mastercard} alt="MASTERCARD" />
                          <img src={mtn} alt="MTN MOMO" />
                          <img src={airtel} alt="AIRTEL MOMO" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sub-cta">
                    <a href="#reviews" className="button uppercase bold">
                      See Reviews
                    </a>
                  </div>
                </div>
              </div>
              <Slide top>
                <div className="image-side">
                  <div className="background"></div>
                  <img
                    src={cover}
                    alt="The Lifetime Treasure Book"
                    className="cover"
                  />
                </div>
              </Slide>
            </div>
            <div className="hero-media">
              <div className="carousel">
                <div className="slider-items-container hero-review-slide">
                  <Slider {...heroReviewsCarousel}>
                    <ReviewSlideCard
                      revName="Bruno Mukisa"
                      revText="The Lifetime Treasure book teaches you how to have an edge over everyone else in coping with life"
                    />
                    <ReviewSlideCard
                      revName="TRENTAH RAIHANAH YUSUF"
                      revText="After your religion's holy book, The Lifetime
                      Treasure Book is one of the best books you will ever read
                      for the good of all aspects of your life"
                    />
                    <ReviewSlideCard
                      revName="Ataho Ndorwa Ali"
                      revText="The Lifetime Treasure book helped me learn to put my intellect before my emotions"
                    />
                    <ReviewSlideCard
                      revName="Aggrey Osuta Snr"
                      revText="The Lifetime Treasure book is a generous companion that arms you with skills that'll give you unlimited leverage in your quest for better living"
                    />
                    <ReviewSlideCard
                      revName="Aryatunga Criscent"
                      revText="If you want exceptional wisdom on how to perfectly live the best of your life and never regret, read The Lifetime Treasure book"
                    />
                    <ReviewSlideCard
                      revName="Magezi Bashir"
                      revText="The Lifetime Treasure book should be incoporated in the syllabus of the education system at all levels"
                    />
                    <ReviewSlideCard
                      revName="Fahad Bruhan Jr."
                      revText="I wish I read The Lifetime Treasure book many years ago!"
                    />
                  </Slider>
                </div>
              </div>
            </div>
            <div className="section-footer center width-300 margin-auto">
              <div className="wft section-spacing-40 no-padding-bottom">
                <img
                  src={wft}
                  alt="Word For Today Publications"
                  className="wft-img"
                />
              </div>
              <p className="rich-small center uppercase margin-bottom-10">
                books by malidad.com are published, marketed and distributed by
                word for today publications limited{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
