import React from "react";
import logo from "../../assets/images/logo/tlt100.png";
import tt from "../../assets/images/icons/twitter.svg";
import fb from "../../assets/images/icons/facebook.svg";
import wp from "../../assets/images/icons/whatsapp.svg";

const Footer = () => {
  return (
    <section className="footer dark-bg section-spacing-20 no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <ul className="footer-menu">
              <li>
                <ul className="footer-menu-item bold">
                  <li>
                    <a href="#about">About Book</a>
                  </li>
                  <li>
                    <a href="#inside">Inside The Book</a>
                  </li>
                  <li>
                    <a href="https://books.malidad.com">
                      Other Books by MALIDAD.COM
                    </a>
                  </li>
                </ul>
              </li>
              <li className="main-book">
                <ul className="footer-menu-item">
                  <li>
                    <div className="site-book">
                      <div className="book">
                        <img
                          src={logo}
                          alt="The Lifetime Treasure Book"
                          className="tltlogo"
                        />
                        <h3 className="uppercase">
                          The Lifetime <br></br>Treasure Book
                        </h3>
                        <p className="bold">
                          Learn and master the 18 skills that pay off forever
                          but school will not teach you
                        </p>
                      </div>
                      <div className="socials flex space-flex-wrap section-spacing-10">
                        <a href="https://twitter.com/MalidadBooks">
                          <img src={tt} className="icon" />
                        </a>
                        <a href="https://facebook.com/MALIDADcom">
                          <img src={fb} className="icon" />
                        </a>
                        <a href="https://wa.me/13152742122">
                          <img src={wp} className="icon" />
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <ul className="footer-menu-item footer-menu-item2 bold">
                  <li>
                    <a href="#reviews">Reviews</a>
                  </li>
                  <li>
                    <a href="/how-to-order-the-lifetime-treasure-book-online">
                      How To Order
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://form.jotform.com/booksmalidadcom/bulk-order-form-thelifetimetreasure"
                    >
                      Bulk Buy Book
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="copy uppercase center smoky-white  section-spacing-10 no-padding-top">
              <p>&copy; 2022, The Malidad Company, All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
      <div className="designer padding-4 smoky-bg">
        <div className="design-inner center dark uppercase">
          <p>
            Website designed by{" "}
            <a href="https://ntuna.com" className="bold light-blue">
              NTUNA.COM
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
