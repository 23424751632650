import React, { useState } from "react";
import prof from "../../assets/images/who/prof.svg";
import doc from "../../assets/images/who/doctor.svg";
import ent from "../../assets/images/who/ent.svg";
import gov from "../../assets/images/who/governor.svg";
import leader from "../../assets/images/who/leader.svg";
import lovers from "../../assets/images/who/lovers.svg";
import manager from "../../assets/images/who/manager.svg";
import married from "../../assets/images/who/married.svg";
import parent from "../../assets/images/who/parent.svg";
import sec from "../../assets/images/who/security.svg";
import social from "../../assets/images/who/social.svg";
import startup from "../../assets/images/who/startup.svg";
import student from "../../assets/images/who/student.svg";
import WhoCard from "../reusables/WhoCard";
import { Slide } from "react-reveal";
import Modal from "../reusables/Modal";
import BuyModalContents from "../reusables/BuyModalContents";

const WhoFor = () => {
  const [show, setShow] = useState(false);
  return (
    <section className="section-spacing-80 no-padding-bottom" id="who">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide right>
              <div className="section-head center width-300 margin-auto">
                <h3 className="section-title title">
                  Who is{" "}
                  <span className=" light-yellow">
                    The Lifetime Treasure Book
                  </span>{" "}
                  for?
                </h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-40 no-padding-bottom">
              <div className="who-wrap relative">
                <div className="who-container flex">
                  <WhoCard
                    icon={prof}
                    who="Professional"
                    text="From all waking areas of life"
                  />
                  <WhoCard
                    icon={ent}
                    who="Entreprenuers"
                    text="The lifetime value for your business and life"
                  />
                  <WhoCard
                    icon={gov}
                    who="Government officials"
                    text="A shield for governance dilemas"
                  />
                  <WhoCard
                    icon={student}
                    who="Students"
                    text="Step by step hacks and tips to understand &amp; remember what you set out to learn"
                  />
                  <WhoCard
                    icon={parent}
                    who="Parents"
                    text="Value beyond personal benefits and goes on to your child grooming acumen"
                  />
                  <WhoCard
                    icon={sec}
                    who="Security Authorities"
                    text="Helping secure the safety of your life as you do for others"
                  />
                  <WhoCard
                    icon={manager}
                    who="Managers"
                    text="Helping you better your man-to-man handling and self"
                  />
                  <WhoCard
                    icon={startup}
                    who="Startup Founders"
                    text="This is a kind of fuel you will ever need for your startup journey"
                  />
                  <WhoCard
                    icon={leader}
                    who="Leaders"
                    text="Packed with ideas and hacks to help you make the most of your leadership"
                  />
                  <WhoCard
                    icon={married}
                    who="The Married"
                    text="Packed with ideas to make the most of your marriage and kids blessed in"
                  />
                  <WhoCard
                    icon={lovers}
                    who="Love Birds"
                    text="When your love affair goes left, this book helps and guides you to right"
                  />
                </div>
              </div>
            </div>
            <div className="section-footer center section-spacing-40 no-padding-bottom">
              <div className="cta-container section-spacing-20">
                <button
                  onClick={() => setShow(true)}
                  className="book-cta button light-yellow-button bold uppercase"
                >
                  Buy Book Now
                </button>
                <Modal onClose={() => setShow(false)} show={show}>
                  <BuyModalContents />
                </Modal>
              </div>
              <p className="section-spacing-10">You will surely love it!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoFor;
