import React, { useState } from "react";
import { Slide } from "react-reveal";
import cover from "../../assets/images/content/tlt-piled.png";
import BuyModalContents from "../reusables/BuyModalContents";
import Modal from "../reusables/Modal";

const AboutBook = () => {
  const [show, setShow] = useState(false);
  return (
    <section className="section-spacing-100" id="about">
      <div className="about-inner">
        <div className="container">
          <div className="large">
            <div className="section-content-container">
              <Slide top>
                <div className="section-head center width-300 margin-auto">
                  <h3 className="section-title title white">
                    About{" "}
                    <span className=" light-yellow">
                      The Lifetime Treasure Book
                    </span>
                  </h3>
                </div>
              </Slide>
              <div className="section-body section-spacing-40">
                <div className="about-container flex">
                  <div className="about-text-side">
                    <h4>
                      School prepares you to pass school exams, The Lifetime
                      Treasure book prepares you to pass life exams
                    </h4>
                    <p className="margin-top-10 ">
                      We assume you have been told by several people and have
                      read several writings and books telling you to learn
                      lifelong skills. But, there was never a clear articulation
                      of those skills and precisely how to learn them. The
                      Lifetime Treasure book breaks down and teaches you those
                      lifelong and most valuable skills anyone can acquire.
                    </p>
                    <p className="margin-top-10 ">
                      These particular skills have the largest impact in
                      anyone's personal and professional life. Once you acquire
                      them, they stick with you for your entire life and will
                      continuously improve the quality of your existence.
                    </p>
                    <p className="margin-top-10 ">
                      The Lifetime Treasure Book will work for you irrespective
                      of the field you are in, the work you do, and the position
                      or status you hold in a company or a nation. It is useful
                      to everyone who wants to live a more meaningful life, from
                      house-wives to presidents that you can even gift it to
                      your heir.
                    </p>
                    <div className="margin-top-10 center bold light-yellow">
                      526 Pages -|- Paperback ISBN: 978-0-620-99318-0
                    </div>
                    <p className="margin-top-10 center boldfont">
                      <q className="white italic">
                        The Lifetime Treasure book is a book of forever profits.
                        A generous companion that arms you with skills that'll
                        give you unlimited leverage in your quest for better
                        living. Make it a point to read it and learn. It's worth
                        every minute of your time!
                      </q>{" "}
                      <span className="center white boldfont">
                        - Dr. Aggrey Osuta Snr.
                      </span>
                    </p>
                    <div className="cta-container section-spacing-20">
                      <button
                        onClick={() => setShow(true)}
                        className="book-cta button light-yellow-button bold uppercase"
                      >
                        Order Your Copy of the Treasure
                      </button>
                      <Modal onClose={() => setShow(false)} show={show}>
                        <BuyModalContents />
                      </Modal>
                    </div>
                  </div>
                  <div className="about-image-side">
                    <img
                      src={cover}
                      alt="The Lifetime Treasure Book"
                      className="cover2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBook;
