import React from "react";
import ThankYouBody from "../components/thank-you/ThankYouBody";

const ThankYou = () => {
  return (
    <>
      <ThankYouBody />
    </>
  );
};

export default ThankYou;
