import React from "react";
import { Helmet } from "react-helmet";
import BuyBody from "../components/buy/BuyBody";

const Buy = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.DadDaughterBook.com/buy" />
        <title>Buy Dad Daughter Book Copy</title>
      </Helmet>
      <BuyBody />
    </>
  );
};

export default Buy;
