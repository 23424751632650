import React from 'react';


const Modal = (props) => {
  return (
    <div className={`modal-contents-container ${props.show ? "show" : ""}`}>
      <div
        className="modal-contents-inner width-600 white-bg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-close flex">
          <button
            onClick={props.onClose}
            className="modal-close-content button modal-close-button bold"
          >
            Close
          </button>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
