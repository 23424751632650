import React from "react";
import dd from "../../assets/images/content/dd.png";
import ds from "../../assets/images/content/ds.png";
import BookOutCardBrief from "../reusables/BookOutCardBrief";

const ThankYouBody = () => {
  return (
    <section className=" section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container threads-bg width-800 margin-auto box-shadow padding-8 border-radius-2">
            <div className="centered">
              <div className=" centered-inner">
                <div className="thanks-head center margin-top-20">
                  <h1 className="uppercase">Thank you for your pre-order!</h1>
                </div>
                <div className="thanks-body section-spacing-40 center width-300 margin-auto">
                  <p>
                    Your copy(ies) for The Lifetime Treasure book will be
                    delivered to your address immediately when out.
                  </p>
                </div>
                <div className="thanks-others">
                  <h2 className="center uppercase">More Reads For You</h2>
                  <div>
                    <div className="outbooks-cards-container">
                      <div className="outbooks-cards-container">
                        <BookOutCardBrief
                          cover={dd}
                          cardClass="btreasure"
                          bookTitle="Dad Daughter"
                          bookSubtitle="200 Life Lessons Every Father Must Teach His Father"
                          bookLink="https://DadDaughterBook.com"
                          cardCtaClass="lyellow"
                        />
                        <BookOutCardBrief
                          cover={ds}
                          cardClass="bdaddaughter"
                          bookTitle="Dad Son"
                          bookSubtitle="200 Life Lessons Every Father Must Teach His Son"
                          bookLink="https://DadSonBook.com"
                          cardCtaClass="lyellow"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYouBody;
