import React, { useState } from "react";
import logo from "../../assets/images/logo/logo100.png";
import BuyResponsive from "../reusables/BuyResponsive";

const Header = () => {
  const [showParent, setShowParent] = useState(false);
  return (
    <header className="header dark-bg" id="header">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="header-contents flex">
              <a href="/" title="The Lifetime Treasure" className="site-brand">
                <img src={logo} alt="#TLTBook" className="tltlogo" />
              </a>
              <nav className="nav">
                <label
                  onClick={() => setShowParent(true)}
                  htmlFor="toggle-responsive-menu"
                  className="nav-label bold"
                >
                  &#9776;
                </label>
                <input type="checkbox" id="toggle-responsive-menu" />

                <div
                  onClose={() => setShowParent(false)}
                  showParent={showParent}
                  className={`nav-inner ${showParent ? "showParent" : ""}`}
                >
                  <div className="hide-menu">
                    <button
                      onClick={() => setShowParent(false)}
                      className="hide-menu-btn button"
                    >
                      X
                    </button>
                  </div>
                  <ul
                    className="site-menu"
                    onClick={() => setShowParent(false)}
                  >
                    <li>
                      <a href="/#reviews">Reviews</a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://form.jotform.com/booksmalidadcom/bulk-order-form-thelifetimetreasure"
                      >
                        Bulk Buy
                      </a>
                    </li>
                    <li className="hide-menu-item">
                      <a href="/how-to-order-the-lifetime-treasure-book-online">
                        How To Order
                      </a>
                    </li>
                    <li>
                      <a href="/#about" target="_parent">
                        About Book
                      </a>
                    </li>
                    <li>
                      <a href="/#who">Who is book for?</a>
                    </li>
                  </ul>
                  <BuyResponsive />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
