import React from "react";
import { Slide } from "react-reveal";
import CheckOutFormPremiumSon from "../reusables/CheckOutFormPremiumSon";

const BuyPremiumSonBundleBody = () => {
  return (
    <section className="section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center margin-auto">
                <h3 className="modal-title uppercase">
                  MALIDAD BOOKS Premium Son Bundle{" "}
                  <p className="light-blue">
                    (DAD SON + THE LIFETIME TREASURE) BOOKS
                  </p>
                </h3>
                <p className=" margin-top-10 dark">
                  Ordering 50+ copies of The Lifetime Treasure book alone? Click{" "}
                  <a
                    href="https://form.jotform.com/booksmalidadcom/bulk-order-form-thelifetimetreasure"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Here
                  </a>
                </p>
              </div>
            </Slide>
            <div className="section-body section-spacing-50 no-padding-bottom">
              <div className=" width-600 margin-auto padding-8 threads-bg light-blue-bg border-radius-2 box-shadow">
                <CheckOutFormPremiumSon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuyPremiumSonBundleBody;
