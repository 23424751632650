import React from "react";

const WhoCard = (props) => {
  return (
    <div className="who-card">
      <div className="icon-side">
        <img src={props.icon} />
      </div>
      <div className="text-side">
        <h3 className="who bold">{props.who}</h3>
        <p className="who-text">{props.text}</p>
      </div>
    </div>
  );
};

export default WhoCard;
