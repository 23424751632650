import React from "react";
import CheckOutForm from "./CheckOutForm";

const BuyModalContents = () => {
  return (
    <div className="modal-contents section-spacing-20 threads-bg">
      <div className="modal-head center">
        <h3 className="modal-title uppercase font-size-20">
          buy the lifetime treasure book:
        </h3>
        <p className=" margin-top-10 dark">
          Pre-ordering 50+ copies? Click{" "}
          <a
            href="https://form.jotform.com/booksmalidadcom/bulk-order-form-thelifetimetreasure"
            target="_blank"
            rel="noopener noreferrer"
          >
            Here
          </a>
        </p>
      </div>
      <div className="modal-body left section-spacing-40">
        <CheckOutForm />
      </div>
    </div>
  );
};

export default BuyModalContents;
