import React from "react";

const BookOutCard = (props) => {
  return (
    <div className="outbook-card center">
      <div className="out-image">
        <img src={props.cover} alt={props.bookTitle} className="out-img" />
      </div>
      <div className={`outbook-card-inner center ${props.cardClass}`}>
        <div className="bookcard-head">
          <h3 className=" title uppercase white">{props.bookTitle}</h3>
        </div>
        <div className="bookcard-body smoky-white">{props.bookSubtitle}</div>
        <div className="bookcard-footer">
          <a
            href={props.bookLink}
            className={`button book-cta lyellow bold ${props.cardCtaClass}`}
          >
            see Book Details
          </a>
        </div>
      </div>
    </div>
  );
};

export default BookOutCard;
