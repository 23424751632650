import React from "react";
import { Helmet } from "react-helmet";
import BuyPremiumPlusBundleBody from "../components/buy/BuyPremiumPlusBundleBody";

const BuyPremiumPlusBundle = () => {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.DadDaughterBook.com/buy-premium-plus-bundle"
        />
        <title>Buy Premium Bundle of MALIDAD.COM</title>
      </Helmet>
      <BuyPremiumPlusBundleBody />
    </>
  );
};

export default BuyPremiumPlusBundle;
